.navbar .dropdown img {
  margin-right: 5px;
}
.navbar .dropdown-menu {
  margin-top: 5px !important;
  min-width: 92px;
  margin-left: -5px !important;
}
.nodes .item {
  padding-right: 15px;
}
.modal-dialog {
  height: 100%;
}
.modal-content {
  padding: 15px;
  background: #2f3144;
}
.modal-header {
  padding: 1rem 1rem;
}
.news_list {
  height: 75vh;
  overflow: scroll;
}
.news_list::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.errorMessage {
  margin-top: 5px;
  color: #e63d40;
  margin-bottom: 10px;
}

.image_div {
  display: inline-block;
  position: relative;
}

.btnimg {
  background: rgb(255 255 255 / 25%);
  pointer-events: none;
  width: 80px;
  height: 80px;
  opacity: 1;
  line-height: 90px;
  text-align: center;
  padding: 5px;
}

input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal-avatar .modal_close {
  width: 100%;
  text-align: center;
}
.modal-avatar .modal_close img {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.modal-simple .modal_close {
  width: 100%;
  text-align: right;
}

.modal-simple .modal_close img {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.color-yellow {
  color: yellow;
}

.form-control {
  height: 50px;
}

.active,
.active h3 {
  color: #5e93de;
}

.loader-btn {
  color: #fff;
  background: #0b5ed7;
  border-color: #0b5ed7;
}

.loader-spinner {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.qr-code {
  margin-inline: 10%;
  padding-inline: 10%;
}

.menu_tabs {
  display: flex;
  width: auto;
  cursor: pointer;
}

.menu_tabs .active_tab {
  border-bottom: 3px solid #fff;
}

.sign .btn-request {
  top: 5px !important;
  color: #FFFFFF;
}

@media only screen and (max-width: 767px) {
  .article {
    margin: 10px;
    width: auto !important;
  }
  .ReactModal__Content {
    font-size: 12px;
    inset: 20px !important;
    padding: 5px !important;
  }
  .modal-content {
    padding: 5px;
  }
  .qr-code {
    margin-inline: 5%;
    padding-inline: 0%;
  }
  .sign .btn-request {
    top: 10px !important;
  }
}
